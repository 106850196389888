import type { GetStaticProps, NextPage } from 'next'
import { useTranslations } from 'next-intl'
import { NextSeo } from 'next-seo'

import { ErrorPage } from '~modules/error/pages/Error.page'

export const getStaticProps: GetStaticProps = async ({ locale = 'es-MX' }) => {
  return {
    props: {
      messages: (await import(`../locales/${locale}.js`)).default,
    },
  }
}

const ErrorNextPage: NextPage = () => {
  const t = useTranslations('error')

  return (
    <>
      <NextSeo title={t('metaTitle')} description={t('metaDescription')} />
      <ErrorPage />
    </>
  )
}

ErrorNextPage.displayName = 'Error'

export default ErrorNextPage
